div.faqContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
div.faqHeading {
  display: flex;
  justify-content: center;
  width: 60vw;
  font-size: 34px;
  font-weight: bolder;
  margin-bottom: 20px;
  margin-top: 4rem;
  padding: 5px 0;
  color: var(--cpotheme);
}
div.faqSubHeading {
  width: 60vw;
  font-size: 26px;
  color: var(--cpotheme);
  border-bottom: 1px solid var(--cpotheme);
}

div.faqCpo {
  width: 60vw;
  padding: 20px;
}
div.faqCpo > h3 {
  margin-bottom: 5px;
}

div.faqCpo > p {
  color: var(--cpofontgrey);
  margin-bottom: 2.3rem;
}
