@import url("https://fonts.googleapis.com/css2?family=Comfortaa&family=Didact+Gothic&family=Mulish:wght@200&family=Nunito+Sans:opsz@6..12&family=Nunito:wght@500;600;700&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Didact+Gothic&family=Mulish:wght@200&family=Nunito:wght@500;600;700&display=swap'); */
:root {
  --cpogrey: #ddd;
  --cpofontgrey: #717171;
  --cpotheme: #ea5355;
}

body {
  margin: 0;
  font-family: "Comfortaa", sans-serif;
  font-family: "Didact Gothic", sans-serif;
  font-family: "Mulish", sans-serif;
  font-family: "Nunito", sans-serif;
  font-family: "Nunito Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.cell {
  border: 1px solid var(--cpogrey) !important;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style-type: none;
}

/* Force scrollbar to be always visible */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: var(--cpotheme);
  border-radius: 1rem;
}

/* Disable tap highlight color on mobile devices */
* {
  -webkit-tap-highlight-color: transparent;
}

.darkModeHidden {
  display: none !important;
}
.marginTop20 {
  margin-top: 20px;
}
.marginLeft110 {
  margin-left: 110px;
}
.width100 {
  width: 150px;
  margin-left: -27px;
}
.visibilityHidden {
  visibility: hidden !important;
}
