div.approvalContainer {
  /* width: 100%; */
}
div.approvalSubHeading {
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: var(--cpotheme);
  padding: 8px 0;
  border-bottom: 2px solid var(--cpogrey);
}
div.approvalCards {
  height: 92vh;
  overflow: scroll;
}
div.approvalCard {
  height: 120px;
  background-color: #e5e5e583;
  margin: 5px;
  margin-bottom: 5px;
  display: flex;
}
div.lineBreak {
  border-bottom: 2px solid var(--cpogrey);
  margin: 10px 5px;
}
div.approvalCardLeft,
div.approvalCardRight,
div.approvalCardCenter {
  height: 100%;
  width: 25%;
}
div.approvalCardSiteName {
  padding: 30px 0 0 20px;
}
div.approvalCardSiteCity {
  padding: 10px 20px;
}
div.approvalStatus {
  padding: 43px 40px;
}
span.bold {
  font-weight: bold;
}
span.cpofontGrey {
  color: var(--cpofontgrey);
}
div.approvalStatusPending,
div.approvalStatusRejected,
div.approvalStatusApproved {
  display: flex;
  justify-content: center;
  width: 70px;
  padding: 5px 10px;
  border-radius: 1rem;
  background-color: #fff;
  font-weight: bold;
}
div.approvalStatusPending {
  color: rgb(255, 166, 0);
  border: 1px solid rgb(255, 166, 0);
}
div.approvalStatusRejected {
  color: red;
  border: 1px solid red;
}
div.approvalStatusApproved {
  color: green;
  border: 1px solid green;
}
div.approvalCardComment {
  color: var(--cpofontgrey);
  padding: 50px 0;
  width: 85px;
}
div.approvalCardCommentText {
  /* width: 200px; */
  font-size: small;
  margin-left: 5px;
  padding: 5px;
  border: 1px solid var(--cpofontgrey);
  border-radius: 1rem;
  height: 100px;
  overflow: auto;
}
div.approvalCardRight {
  width: 49.5%;
  display: flex;
  /* padding: 2px; */
  align-items: center;
}
