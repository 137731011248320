.home {
  display: flex;
}

.sidebar {
  flex: 1;
  border-right: 1px solid lightgray;
  min-height: 100vh;
  min-width: 191px;
}

.top {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.logo {
  font-size: 18px;
  font-weight: bold;
  color: var(--cpotheme);
}

hr {
  height: 0;
  border: 0.5px solid lightgray;
}
.sidebar-li {
  border-bottom: 1px solid lightgray;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-right: 5px; */
}

ul.column {
  list-style: none;
  margin: 5px;
  padding: 0;
}

li.column {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgb(218, 214, 214);
  cursor: pointer;
}

li.column:hover,
li.sidebar-li:hover {
  background-color: #e5e5e583;
  font-weight: bold;
  cursor: pointer;
}

.icon {
  color: rgb(225, 74, 48);
  margin: 5px 10px;
}
span.top {
  font-size: 14px;
  font-weight: 600;
  color: #888;
  margin-left: 10px;
}

.home-container {
  flex: 7;
}
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f7f7f7;
}

h2.column {
  text-align: center;
}

form.column {
  display: flex;
  flex-direction: column;
}

.row {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;
}

.column {
  flex: 1;
  margin-right: 10px;
}

.row > label {
  margin-top: 10px;
}

input[type="number"],
input[type="text"].special-input,
textarea,
select,
input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.row > .column > select {
  border: 1px solid #ccc;
}

button[type="submit"],
.clear-form-data {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 3rem;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}
button[type="submit"]:active {
  background-color: #004085;
}

button.clear-form-data {
  background-color: rgba(255, 255, 255);
  color: var(--cpotheme);
  border: 1px solid var(--cpotheme);
  font-weight: bold;
}

.clear-form-data:hover {
  background-color: rgb(218, 0, 0);
  color: #fff;
}
.clear-form-data:active {
  background-color: rgb(176, 65, 67);
}

.bulk-upload {
  padding: 20px;
  margin-bottom: 3rem;
  width: 40%;
}

button.adminSubmitBtn {
  background-color: var(--cpotheme);
  font-weight: bold;
}
button.adminSubmitBtn:hover {
  background-color: rgb(218, 0, 0) !important;
}
button.adminSubmitBtn:active {
  background-color: rgb(176, 65, 67) !important;
}

.dropdown {
  /* width: 200px; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.checkbox-options {
  display: flex;
  flex-direction: column;
}

label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

input[type="checkbox"] {
  margin-right: 5px;
}

div.draftsContainer {
  min-height: 98vh;
}

div.draftItemsContainer {
  /* width: 100%; */
  height: 100%;
  overflow: auto;
  margin-top: 5px;
  margin: 5px;
}

div.draftCard {
  height: 120px;
  display: flex;
  border: 1px solid var(--cpogrey);
  margin-bottom: 5px;
  background-color: #f5f5f5;
  cursor: pointer;
}
div.draftCard:hover {
  background-color: #e2e2e2;
}

div.draftLeft,
div.draftCenter,
div.draftRight {
  padding: 10px;
  width: 33.3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.bulkUploadContainer {
  /* height: 100%; */
}

div.bulkUploadHeading,
div.activityLogsHeading,
div.adminHeading,
div.draftsTopBar,
div.siteEditorHeading {
  width: 100%;
  height: 34px;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: var(--cpotheme);
  padding: 8px 0;
  border-bottom: 2px solid var(--cpogrey);
}
div.bulkUploadInput {
  width: 40%;
  padding: 5px;
}
div.bulkDataShow {
  width: 60%;
  padding: 5px;
}
div.bulkUploadMain {
  display: flex;
  height: 100%;
}
h2.bulkDataSSubHeading {
  margin-bottom: 5px;
  font-size: 20px;
}

div.activityLogsContainer {
}
div.activityLogsHeading {
}
div.bulkUploadedText {
  height: 80vh;
  width: 95%;
  overflow: scroll;
  padding: 5px;
  border: 1px solid var(--cpogrey);
}

span.fieldname {
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.addASiteFields {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  border-right: 1px dashed var(--cpogrey);
}
div.adminSubContainer {
  display: flex;
}
div.addASiteFieldDetail {
  width: 25%;
  position: fixed;
  right: 20px;
  top: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 100px;
}
.strongOfImage{
  color: #007bff;
}
.addASiteDetailBox-img{
position: relative;
max-width: 100%;
max-height: 100%;
}
div.addASiteDetailBox {
  width: 80%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid var(--cpotheme);
  background-color: #000000ae;
  color: #fff;
  border-radius: 2rem;
  font-size: large;
  font-weight: bold;
  padding: 15px;
}

div.adminAddImagesContainer {
  width: 70%;
  margin-top: 20vh;
  margin-bottom: 10vh;
}
div.imagesUploaderContainer {
  width: 70%;
  border-right: 1px dashed var(--cpogrey);
}

span.addImagesTitle {
  width: 200px;
}
div.adminAddImage {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  gap: 3rem;
  margin-left: 15%;
}
span.addImageInputField {
  display: flex;
  align-items: center;
}
button.addImageFieldBtn {
  height: 40px;
  width: 40px;
  color: #fff;
  background-color: #ea5355;
  border: none;
  border-radius: 5px;
  font-size: 20px;
}
button.removeImageFieldBtn {
  height: 40px;
  width: 40px;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  margin-left: 10px;
}
div.uploadImagesHeading {
  font-size: 24px;
  font-weight: bold;
  padding: 30px;
  border-top: 1px dashed var(--cpogrey);
  border-bottom: 1px dashed var(--cpogrey);
  margin-bottom: 20px;
}
.uploadImagesHeading-1 {
  font-size: 24px;
  font-weight: bold;
  padding: 30px;
  
  border-bottom: 1px dashed var(--cpogrey);
  margin-bottom: 50px;
}
button.adminImageUploadBtn {
  height: 40px;
  width: 80px;
  color: #fff;
  background-color: #ea5355;
  border: none;
  border-radius: 5px;
  font-size: medium;
  font-weight: bold;
  margin-left: 15%;
  margin-bottom: 50px;
}
button.adminImageUploadBtn:hover {
  background-color: #d12f32;
}
button.adminImageUploadBtn:active {
  background-color: #ff282c;
}
button.activeBlack {
  background-color: #000000 !important;
}
button.activeBlack:active {
  background-color: #9b9b9bfe !important;
}

div.adminFacilitiesDropdown {
  position: absolute;
  background-color: #fff;
  width: 49%;
  height: 50vh;
  min-height: 300px;
  border: 1px solid var(--cpofontgrey);
  margin-left: -10px;
  overflow: auto;
}

div.bulkUploadOperationsContainer {
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
button.bulkUploadBtn {
  margin-left: 20px;
  height: 40px;
  width: 80px;
  color: #fff;
  background-color: #ea5355;
  border: none;
  border-radius: 5px;
  font-size: medium;
  font-weight: bold;
  margin-bottom: 50px;
}
button.bulkUploadBtn:hover {
  background-color: #d12f32;
}
button.bulkUploadBtn:active {
  background-color: #ff282c;
}

button.bulkUploadClearBtn,
button.backtohomebtnclass {
  margin-left: 20px;
  height: 40px;
  width: 80px;
  color: var(--cpotheme);
  background-color: #fff;
  border: 1px solid var(--cpotheme);
  border-radius: 5px;
  font-size: medium;
  font-weight: bold;
  margin-bottom: 50px;
}

button.bulkUploadClearBtn:hover {
  border: 2px solid var(--cpotheme);
}

button.bulkUploadClearBtn:active {
  background-color: var(--cpotheme);
  color: #fff;
}

.adminLiColored {
  background-color: var(--cpofontgrey) !important;
  color: #fff !important;
}
.adminLiColored:hover {
  background-color: var(--cpofontgrey) !important;
}
div.adminNoAccess {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--cpotheme);
}
button.backtohomebtnclass {
  margin-top: 20px !important;
  cursor: pointer;
}
