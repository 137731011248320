hr.siteDetailsHR {
  margin-top: 10px;
  color: var(--cpogrey);
  border-top: 0;
}

div.pageHeading {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  width: 100%;
  height: 80px;
  margin-top: 4rem;
  /* margin-bottom: 0; */
}

h1.siteNameH1 {
  font-size: 26px;
}
h1.siteNameH1,
p.siteNameP {
  margin-bottom: 0px;
}

div.siteName {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100vw - 28%);
  max-width: 1121px;
  margin: auto;
}

h3.ratingProductDetails {
  font-size: 16px !important;
  font-weight: normal;
  margin-bottom: 0px;
  padding: 2px;
}

span.saveSpan {
  margin-left: 5px;
  text-decoration: underline;
  font-size: 15px;
  font-weight: bold;
}

.wishlistIcon {
  display: flex;
  justify-content: flex-end;
  margin-right: 2px;
  height: 21.6px;
  cursor: pointer;
  padding: 2.1px;
  border-radius: 5px;
}

.wishlistIcon:active {
  color: var(--cpotheme);
}
.pageImages {
  /* height: 365px; */
  max-height: 365px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  gap: 8px;
}
img.mainImage {
  width: 38vw;
  min-width: 320px;
  max-width: 560px !important;
  height: 365px;
  /* min-height: 300px; */
  border-radius: 1rem 0 0 1rem;
  cursor: pointer;
  display: flex;
  flex-shrink: 1;
  object-fit: cover;
  object-position: center center;
}
img.mainImage:hover {
  filter: brightness(0.9);
}
.subImages1,
.subImages2 {
  max-height: 345px;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  /* max-height: 365px;
  min-height: 300px; */
}
.subimg11,
.subimg12,
.subimg21,
.subimg22 {
  width: 18vw;
  min-width: 138px;
  max-width: 272px;
  /* min-height: 143px; */
  height: 178.5px !important;
  margin-bottom: 8px;
  cursor: pointer;
  object-fit: cover;
  object-position: center center;
  /* filter: blur(7px) brightness(0.9) contrast(1.3); */
}

img.subimg11:hover,
img.subimg12:hover,
img.subimg21:hover,
img.subimg22:hover {
  filter: brightness(0.9);
}

.subimg21 {
  border-radius: 0 1rem 0 0;
}
.subimg22 {
  border-radius: 0 0 1rem 0;
}

div.siteDetailL1 {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

div.siteAddress {
  margin-top: 2rem;
}

h2.siteAddressH2 {
  margin-bottom: 5px;
  font-size: 22px;
}
/* div.siteMultipleDetails,
div.siteAddress {
  width: 42.3vw;
} */
div.siteMultipleDetails {
  margin-bottom: 2rem;
  max-width: 657px;
}
.addressDetails {
  margin-right: 2rem;
  font-size: medium;
  color: var(--cpofontgrey);
}

div.siteFacilities,
div.siteAboutData {
  padding-top: 32px;
  padding-bottom: 32px;
}
div.nearbyFacilities {
  max-height: 66px;
  overflow: hidden;
}

div.siteAddressDetails {
  margin-bottom: 10px;
}

.siteAccess,
.siteType,
.propertyType {
  margin-top: 10px;
  margin-right: 10px;
  font-size: small;
  color: var(--cpofontgrey);
}

h2.nearbyFacilities,
h2.aboutSite {
  margin-bottom: -20px;
  margin-top: 30px;
  font-size: 22px;
}

.siteType,
.propertyType {
  margin-left: 10px;
}

div.siteOtherDetails {
  padding-top: 48px;
  padding-bottom: 48px;
  font-size: 14px;
  color: var(--cpofontgrey);
}

.siteAboutData,
.siteFacilities {
  color: var(--cpofontgrey);
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.cell {
  padding: 10px;
  border: 1px solid #00000085;
  border-radius: 0.7rem;
}

div.sitePricingCard {
  width: 24vw;
  max-width: 370px;
  min-width: 235px;
  min-height: 180px;
  max-height: 200px;
  height: 22vh;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 24px;
  box-shadow: 0 0 10px 4px rgba(203, 203, 203, 0.405);
  margin-bottom: 2rem;
  margin-top: 3rem;
  position: sticky;
  top: 35%;
  background-color: #fff;
}

div.ownerNameNumber {
  width: 24vw;
  max-width: 370px;
  min-width: 235px;
  height: 12vh;
  min-height: 80px;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 24px;
  box-shadow: 0 0 10px 4px rgba(203, 203, 203, 0.405);
  margin-bottom: 2rem;
  margin-top: 3rem;
  position: sticky;
  top: 12%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

span.ownerDetails {
  font-size: large;
  font-weight: bold;
  margin-bottom: 10px;
}

span.purchasedLogo {
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 12px;
  padding: 1px 4px;
  background-color: var(--cpotheme);
  color: #fff;
  border-radius: 10px;
  /* border: 1px solid rgb(0, 0, 0);   */
  box-shadow: 0px 0px 1px 1px rgb(206, 57, 57);
}

h2.totalPrice {
  /* width: 120px; */
  border-bottom: 2px solid var(--cpogrey);
  font-size: 22px;
}

div.sitePrice {
  width: 322px;
  height: 46px;
  display: flex;
  flex-direction: column;
}

span.priceInNumber {
  font-weight: bold;
  font-size: medium;
  opacity: 0.7;
}
span.priceDetail {
  color: gray;
  font-size: medium;
}
Button.buynow-btn {
  background-color: var(--cpotheme);
  margin-top: 40px;
  width: 100%;
}

Button.buynow-btn:hover {
  background-color: var(--cpotheme);
}

h2.whatThisPlaceOffers {
  color: black;
  font-size: 22px;
}

img.modalExpandedImage {
  width: 600px;
  max-height: 600px;
  border-radius: 8px;
}

img.blurred {
  filter: blur(1px) brightness(0.5) contrast(4) opacity(0.6) !important;
}

span.loginToView {
  position: absolute;
  transform: translate(-50%, 325%);
  font-size: 38px;
  background-color: #fff;
  padding: 0 15px;
  border-radius: 1rem;
  font-weight: bolder;
  color: var(--cpotheme);
  width: 275px;
}
span.verifyToView {
  width: 460px !important;
}

.soldout {
  background-color: var(--cpofontgrey) !important;
}

@media only screen and (min-width: 769px) {
  div.pageHeading {
    margin-left: -5px;
  }
  .wishlistIcon:hover {
    color: var(--cpotheme);
  }
  div.mobileSubHeading {
    display: none !important;
  }
  div.siteMultipleDetails {
  }
  div.mobilePriceCard {
    display: none !important;
  }
  div.mobileSliderBtn {
    display: none !important;
  }
  .mobileSliderBtnContainer {
    display: none !important;
  }
  .propertyTypeMobileView {
    display: none !important;
  }
  div.favouriteSavedText {
    display: flex;
    align-items: center;
  }
  div.ownerNameNumberMobile {
    display: none;
  }

  div.siteDetailL1 {
    min-width: 680px;
    max-width: 84vw;
    margin: auto;
    display: flex;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 1470px) and (min-width: 769px) {
  div.siteDetailsPage {
    /* width: 100vw !important; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  img.mainImage {
    width: 42vw;
    min-width: 300px;
    /* max-width: 560px !important; */
    height: 365px;
    /* min-height: 300px; */
    border-radius: 1rem 0 0 1rem;
    cursor: pointer;
    display: flex;
    flex-shrink: 1;
    object-fit: cover;
    object-position: center center;
  }
  .subimg11,
  .subimg12,
  .subimg21,
  .subimg22 {
    width: 20vw;
    min-width: 190px;
  }

  div.siteDetailL1 {
    width: 100%;
    /* min-width: 425px; */
    max-width: 90vw;
    margin: auto;
    display: flex;
    justify-content: space-around;
    /* flex-shrink: 2; */
  }

  div.siteMultipleDetails {
    margin-bottom: 2rem;
    max-width: 557px;
    width: 52vw;
  }
  /* div.sitePricingCard {
    width: 20vw;
    min-width: 235px;
    max-width: 300px;
    flex-shrink: 2;
    top: 40%;
  } */
  div.pageHeading {
    width: 99%;
    margin-left: -75px;
  }

  .propertyTypeMobileView {
    display: none !important;
  }
}

@media only screen and (max-width: 769px) {
  div.pageHeading {
    gap: 20px;
    height: 100px !important;
    margin-bottom: 0;
  }
  div.mobileSubHeading {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 91vw;
    margin-bottom: 0px;
  }
  div.favouriteSavedTextMobile {
    display: flex;
    align-items: center;
  }
  div.sitePricingCard {
    display: none !important;
  }

  div.siteMultipleDetails,
  div.siteAddress {
    width: 89vw !important;
  }
  div.siteDetails {
    display: flex;
    flex-direction: column;
  }

  span.addressDetails {
    margin-right: 12px !important;
  }
  /* .mainImage{
    display: none;
  } */
  .subImages1,
  .subImages2 {
    display: none;
  }

  .mainImage {
    display: none !important;
  }

  img.mobileViewImage {
    width: 91vw;
    height: 345px;
    border-radius: 1rem;
    cursor: pointer;
    display: flex;
    flex-shrink: 1;
    /* margin-top: 20px; */
  }
  .carouselmobile {
    min-width: 91vw;
  }
  .css-hn784z,
  .css-1abc02a {
    height: 345px !important;
  }

  div.siteName {
    width: 89vw;
  }

  h1.siteNameH1 {
    font-size: x-large;
    margin-bottom: -20px !important;
  }
  div.siteSubHeadings {
    display: flex;
    justify-content: space-between;
    width: 91vw;
    max-width: 1121px;
    margin: auto;
  }
  .propertyType {
    display: none !important;
  }
  div.siteDetailsPage {
    margin-left: -8px;
  }

  h2.aboutSite {
    margin-top: 0px;
  }
  h2.whatThisPlaceOffers {
    margin-top: -20px;
  }

  div.mobilePriceCard {
    position: sticky;
    bottom: 48px;
    background-color: #fff;
    width: 90vw;
    padding-bottom: 30px;
    margin: auto;
    margin-bottom: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 10px !important;
    border-top: 1px solid var(--cpogrey);
    margin-top: -26px;
  }

  div.totalPrice {
    display: flex;
    flex-direction: column;
  }
  span.priceDetail {
    font-size: small !important;
  }
  Button.buynow-btn {
    margin-top: 5px;
  }

  div.pageImages {
    margin-left: 5px;
  }
  div.rightside {
    display: none !important;
  }

  div.ownerNameNumberMobile {
    width: 79vw;
    border: 1px solid #ccc;
    border-radius: 1rem;
    padding: 15px 24px;
    box-shadow: 0 0 10px 4px rgba(203, 203, 203, 0.405);
    margin-bottom: 2rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
  }

  span.ownerDetailsMobile {
    margin-top: -10px !important;
  }

  div.purchasedMobile {
    display: flex;
    justify-content: flex-end;
    width: 81vw;
  }
  span.purchasedLogoMobile {
    font-size: 12px;
    padding: 1px 4px;
    background-color: var(--cpotheme);
    color: #fff;
    border-radius: 10px;
    /* border: 1px solid rgb(0, 0, 0);   */
    box-shadow: 0px 0px 1px 1px rgb(206, 57, 57);
  }
}

.fadedMedium {
  font-size: 15px;
  color: var(--cpofontgrey);
  margin-left: 5px;
  margin-top: 1px;
}

.sameline {
  display: flex;
  height: 24px;
}
