@media only screen and (max-width: 769px) {
  div.profile-container {
    margin-top: 3.5rem;
  }
}

div.profile-container {
  margin-top: 4rem;
}

.home-link {
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 2;
}

.second-navbar {
  z-index: 1;
}
div.profilePictureDiv {
  height: 410px;
}
Button.profile-btn {
  background-color: var(--cpotheme);
}
Button.profile-btn:hover {
  background-color: rgb(212, 3, 3);
}
Button.profile-btn:active {
  background-color: rgb(244, 24, 24);
}

.css-3su884-MuiPaper-root {
  box-shadow: none !important;
}

.verifiedEmailIcon {
  color: var(--cpotheme);
  position: absolute;
  transform: translate(-130%, 35%);
}
.verifiedEmailText {
  color: var(--cpotheme);
  position: absolute;
  transform: translate(-180%, 70%);
  font-size: small;
}
.unverifiedTextSent{
  color: var(--cpofontgrey);
  position: absolute;
  transform: translate(-140%, 55%);
  font-size: small;
  padding: 1px 4px;
  border-radius: 5px;
  background-color: var(--cpogrey);
}

.unverifiedText {
  color: var(--cpotheme);
  position: absolute;
  transform: translate(-120%, 38%);
  font-size: small;
  cursor: pointer;
  border: 2px solid var(--cpotheme);
  padding: 1px 4px;
  border-radius: 5px;
}

Button.verifyUser {
  font-size: small;
}

div.photoUploadModalDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div.photoUploadModalDiv > img {
  border: 5px solid white;
  border-radius: 1rem;
  min-width: 400px;
  max-width: 85vw;
  min-height: 400px;
  max-height: 85vh;
}

div.photoUploadModalButtons {
  display: flex;
  gap: 3rem;
}