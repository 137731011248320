.home-btn {
  position: fixed;
  right: 1.5rem;
  top: 1rem;
  z-index: 6;
  border-radius: 5rem;
  background-color: var(--cpotheme);
  padding: 0.3rem 0.8rem;
  color: #fff;
}
.signin-bottom {
  /* margin-top: 1rem; */
  margin-top: -40px;
  padding: 1rem;
  font-size: small;
}

/* span.login-span {
} */

span.login-span {
  color: var(--cpotheme);
  display: block;
}

.signin-menubar {
  display: flex;
  gap: 5px;
}
.signup-container {
  display: flex;
  justify-content: space-evenly;
}
@media only screen and (min-width: 930px) {
  .signupBox {
    width: 450px;
    scroll-behavior: smooth;
  }

  .signupFieldDetails {
    width: 500px;
    scroll-behavior: smooth;
  }
  .signupDetailsBox {
    width: 450px;
    height: 160px;
    overflow: auto;
    position: fixed;
    top: 40%;
    margin-left: 25px;
    border: 2px solid var(--cpogrey);
    border-radius: 2rem;
    padding: 10px 20px;
    background-color: #ea5356;
    color: #fff;
    box-shadow: 4px 4px 8px 0px #0000007e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .headingSignupDetail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 930px) {
  .signupFieldDetails {
    display: none !important;
  }
  .signupDetailsBox {
    display: none !important;
  }
}
