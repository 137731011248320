.navbar {
  background-color: white;
  /* background-color: #000 !important; */
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 1.5rem;
  /* gap: 1rem; */
  top: 0;
  z-index: 20;
  min-width: 100%;
  border-bottom: 1px solid #e8e8e884 !important;
}

.header-end {
  /* margin-right: -8px; */
}

.MenubarIcon {
  height: 25px;
}
.MenubarIcon:hover {
  color: var(--cpotheme) !important;
}

.bottom-navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
}

.navbar-logo {
  margin-left: 3rem;
  height: 3rem;
}

.search-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 1.5rem;
  padding-left: 8px;
  border: 1px solid var(--cpogrey);
  border-radius: 2rem !important;
  box-shadow: 0 0px 5px 0 rgba(123, 123, 123, 0.2);
  cursor: pointer;
}

.search-bar:hover {
  box-shadow: 0 2px 5px 0 rgba(67, 66, 66, 0.2);
  transition: all 0.2s;
}

.vertical-line {
  height: 1rem;
  border: 1px solid var(--cpogrey);
}

.searchbar-pincode {
  /* color: var(--cpofontgrey); */
  /* background-color: #fff; */
  margin-left: 10px;
}

.search-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cpotheme);
  color: white;
  border-radius: 50%;
  padding: 0.3rem;
  margin-left: 0.5rem;
  margin-right: -0.7rem;
}

.search-icon:active {
  background-color: rgb(175, 0, 0) !important;
}

.profile-bar {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  text-align: center;
  align-items: center;
}

.menu-container {
  display: flex;
  cursor: pointer;
  margin-right: 4.4rem;
  border: 1px solid var(--cpogrey);
  border-radius: 2rem;
  padding: 0.4rem 0.5rem;
}

.menu-container:hover {
  box-shadow: 0 2px 5px 0 var(--cpotheme);
  transition: all 0.3s;
}

.main-nav-hr {
  width: 100%;
  border-top: 1px solid var(--cpogrey);
}

.bottom-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 1px solid var(--cpogrey);
}

.second-navbar {
  display: flex;
  background-color: #fff;
  width: 100%;
  top: 0px;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  margin-right: 20px;
  z-index: 2 !important;
  border-bottom: 1px solid var(--cpogrey);
}

img.second-navbar-logo {
  height: 64px !important;
  padding: 0.5rem;
  margin-left: 2rem;
}

.styled-menu {
  position: absolute;
  top: 2rem;
  border-radius: 20px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 8px;
}

.styled-menu > div > li {
  padding: 8px 16px;
  transition: background-color 0.3s ease;
}

.styled-menu > div > li:hover {
  background-color: #f1f1f1;
}

li.search-item:hover {
  /* font-weight: normal !important;
  border-radius: 5rem !important;
  background-color: #e455315c !important;
  transition: 0.3s ease-in-out; */
  color: #ea5355;
}
li.search-item {
  padding: 10px 10px;
  /* border-bottom: 2px solid var(--cpotheme); */
  /* cursor: pointer; */
  margin-top: -10px;
}

ul.propertyTypeUL {
  display: flex;
  gap: 2rem;
}

li.propertyTypeItem {
  padding: 10px 10px;
  border-bottom: 2px solid var(--cpotheme);
  cursor: pointer;
  margin-top: -10px;
}

.cart-number {
  font-size: xx-small;
  position: inherit;
  margin-left: -1px;
  padding: 0px 4px;
  padding-top: 2px;
  height: 14px;
  background-color: var(--cpotheme);
  color: white;
  border-radius: 50%;
}

.signin-menubar {
  width: 100%;
}
.cart-icon {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.5rem;
}

.header-end {
  display: flex;
  gap: 1rem;
}

.shopping-cart-icon {
  cursor: pointer;
  color: black !important;
}

.cpoassistprofile {
  color: black !important;
}
.unverifiedCart {
  margin-bottom: 8px;
}

@media only screen and (min-width: 769px) {
  .bottom-navbar {
    display: none !important;
  }

  .search-icon:hover {
    background-color: rgb(255, 86, 52) !important;
    box-shadow: 0 0 8px 1px var(--cpotheme);
  }

  .searchbar-text {
    font-size: 16px !important;
    height: 1rem;
  }

  .navbar-logo {
    margin-left: 1rem;
  }
  .logoShort {
    display: none !important;
  }

  .selected-filters {
    position: absolute;
    font-size: x-small;
    display: flex;
    /* top: 3.6rem; */
    transform: translate(10%, 180%);
    gap: 1rem;
    padding: 0.2rem;
    background-color: #fff;
    color: var(--cpotheme);
    border-radius: 2rem;
  }
  img.second-navbar-logo {
    margin-top: -3px;
  }

  ul.search-list {
    min-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-bottom: 10px solid #fff; */
    flex-wrap: wrap;
    scroll-behavior: smooth;
    overflow: scroll;
    margin-top: 10px;
  }
  li.search-item {
    /* margin-right: 32px; */
  }
  .pincodeTextbox > .MuiOutlinedInput-root {
    width: 10rem !important;
  }
  .pincodeTextbox > .MuiInputLabel-root {
    margin: -5px 3px !important;
  }
  .pincodeTextbox > .MuiInputLabel-shrink {
    margin-left: 5px !important;
  }
  .citySearchInput {
    width: 200px !important;
  }
  div.cityListClass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 120px;
    cursor: pointer;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1100px) {
  .search-bar {
    display: flex;
    margin-right: 6rem;
  }
  .logoShort {
    display: none !important;
  }

  ul.search-list {
    min-width: 100vw;
    display: flex;
    align-items: center;
  }

  ul.search-list {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-bottom: 10px solid #fff; */
    flex-wrap: wrap;
    scroll-behavior: smooth;
    overflow: scroll;
    margin-top: 10px;
  }
  li.search-item {
    /* margin-right: 32px; */
  }
  .filterSearchbar {
    margin-right: 30px !important;
  }
}

@media only screen and (max-width: 769px) {
  .navbar {
    /* padding-left: 5px; */
    margin-right: -5px;
  }

  .navbar-logo {
    display: none !important;
  }

  .logoShort {
    height: 43px !important;
    margin-top: 2px !important;
    /* margin-left: -20px; */
  }

  .searchbar-text {
    font-size: 14px !important;
    height: 1rem;
  }
  .selected-filters {
    position: absolute;
    font-size: x-small;
    display: flex;
    left: 1.5rem;
    top: 3.6rem;
    gap: 0.5rem;
    background-color: #fff;
    width: 100vw;
    padding-bottom: 0.2rem;
  }
  .cart-number {
    font-size: 10px !important;
    padding: 0px 4px !important;
  }
  .cart-icon {
    margin-right: -10px !important;
    margin-top: 10px !important;
  }

  img.second-navbar-logo {
    height: 56px !important;
    margin-left: 16px;
  }
  .menu-container {
    display: flex;
    cursor: pointer;
    border: 1px solid var(--cpogrey);
    margin-top: 2px;
    margin-right: 35px;
    /* width: 50px; */
  }
  .property-filter,
  .pincode-filter {
    /* display: none !important; */
    height: 20px;
    width: 20px;
  }

  .city-filter {
    margin-left: -10px !important;
    margin-right: -8px !important;
  }
  .pincodeTextbox > .pincodeTextbox,
  .pincodeTextbox > .MuiOutlinedInput-root,
  .pincodeTextbox > .MuiInputLabel-root {
    height: 22px !important;
    width: 4.2rem !important;
    font-size: 10px !important;
    margin-left: -5px;
    margin-top: -0.4px !important;
  }
  .pincodeTextbox > .MuiInputLabel-root {
    margin: -12px -10px !important;
  }
  .pincodeTextbox > .MuiInputLabel-shrink {
    font-size: 11px !important;
    width: 20px !important;
    margin: -5px -3px !important;
    overflow: visible !important;
  }

  .searchbar-text {
    height: 20px !important;
    font-size: 11px !important;
    width: 50px;
  }
  .search-bar {
    /* width: 3.8rem !important; */
    /* padding-left: 7px; */
    width: 10.5rem;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    /* margin-left: 4rem !important; */
    margin-left: 0.5rem;
    height: 22px;
  }

  .searchbar-text {
    margin-bottom: 2px !important;
  }

  .search-icon {
    width: 12px !important;
    height: 12px !important;
    /* display: none;                               */
    margin-left: 46px;
  }

  ul.search-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* width: 90vw !important; */
    margin-bottom: 10px;
    padding-bottom: 0;
  }
  li.search-itemCity {
    /* margin-top: 16px; */
  }
  ul.propertyTypeUL {
    flex-direction: column;
    width: 90vw;
  }
  .citySearchInput {
    width: 250px !important;
  }
  div.cityListClass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 120px;
    cursor: pointer;
    font-size: 14px;
    padding: 1rem 0;
  }
}

@media only screen and (max-width: 429px) {
  .cart-number {
    display: none !important;
  }
  .cart-icon {
    display: none !important;
  }
  .menu-container {
    width: 50px;
  }
}

.svg-image {
  width: 75px;
  height: 70px;
  margin-bottom: -10px;
}
div.cityListClass:hover {
  filter: drop-shadow(0px 0px 0px #ea5355);
  color: var(--cpotheme);
}

li.propertyTypeHover {
  margin-top: 0px;
}
li.propertyTypeHover {
  transition: color 0.3s;
}

li.propertyTypeHover:hover {
  color: #fff;
  border-radius: 2rem;
  background-color: var(--cpotheme);
}
