div.guestContainer {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.guestSignin {
  cursor: pointer;
}

div.guestMessage {
  margin-left: 5px;
}

div.loadingModalDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff9b;
}

img.loadingModalImage {
  filter: saturate(1.1);
  border-radius: 50%;
}
span.loadingModalText {
  margin-top: 10px;
  color: var(--cpotheme);
}
.popperComponent{
  width: 300px;
  border: 1px solid var(--cpogrey);
  box-shadow: 4px 4px 16px 0px var(--cpofontgrey);
}
div.popperButtons{
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;
}

Button.popperRemoveBtn{
  background-color: var(--cpofontgrey);
}
Button.popperWishlistBtn{
  background-color: var(--cpotheme);
}

Button.popperRemoveBtn:hover{
  background-color: black;
}
Button.popperWishlistBtn:hover {
  background-color: rgb(210, 0, 0);
}

.popperCloseBtn{
  position: absolute;
  right: 2px;
  top: 2px;
  color: var(--cpofontgrey);
}