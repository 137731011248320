.upper-footer {
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  min-height: 200px;
  background-color: #fff;
  font-weight: lighter;
  border-top: 1px solid var(--cpogrey);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 3rem;
  max-height: 3rem;
  background-color: #ffffff;
  font-weight: lighter;
  border-top: 1px solid #e8e8e884;
  z-index: 1;
}

.footer-left {
  margin-left: 2rem;
}

.footer-right {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  position: inherit;
}

span.footerCPO {
  color: var(--cpotheme);
}

span.footerPrivacy {
  color: var(--cpofontgrey);
}

.footer-accordion {
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 30px;
  padding-bottom: 60px;
}

.footer-accordion.expanded {
  max-height: 300px;
  margin-bottom: 30px;
  background-color: #fff;
}

.expand-button.hidden {
  display: none;
}

.footer-content {
  padding: 16px;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.footer-accordion.expanded .footer-content {
  opacity: 1;
}

.close-button.hidden {
  display: none;
}

.expandIcon {
  border: 1px solid var(--cpotheme);
  border-bottom: none;
  color: var(--cpotheme);
  background-color: #fff;
  border-radius: 1rem 1rem 0 0;
  min-width: 47px;
  margin-bottom: 100px !important;
}

img.Xlogo {
  margin-left: 3px;
  padding: 1px;
  border-radius: 4px;
  border: 2px solid black;
}

@media only screen and (min-width: 769px) {
  .footer-right {
    margin-right: 2rem;
  }
  .footer-left {
    margin-left: 2.8rem;
  }
  .expand-button {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50px;
    margin-left: 15px;
    /* height: 40px; */
    padding-top: 65px;
  }
  .close-button {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50px;
    position: relative;
    top: 115px;
    left: 15px;
  }

  .footer-accordion.expanded {
    max-height: 260px;
    margin-bottom: 0;
    background-color: #fff;
  }
  img.Xlogo {
    width: 14.5px !important;
    margin-top: 3px;
  }

  div.companyAddress {
    width: 300px;
  }
  p.aboutCPOA {
    color: var(--cpofontgrey);
  }
  p.CPOAddress {
    color: #000;
    font-size: small;
  }
  .CPOemail {
    color: var(--cpotheme);
  }
}

@media only screen and (max-width: 769px) {
  .footer {
    font-size: 10px;
    position: fixed;
    bottom: 0px !important;
  }

  .footer-left {
    margin-left: 15px;
  }

  .footer-right {
    display: flex;
    position: absolute;
    right: 0px;
    gap: 0.5rem !important;
  }
  .footer {
    max-height: 30px;
  }
  .upper-footer {
    min-height: 170px;
  }
  .expand-button {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50px;
    margin-left: 15px;
    padding-bottom: 0 !important;
    position: fixed;
    bottom: -9px;
    height: 40px !important;
    z-index: 1;
  }
  .close-button {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 50px;
    position: relative;
    top: 116px;
    left: 15px;
    height: 20px;
  }
  .footer-accordion.expanded {
    bottom: 0;
    position: fixed;
    width: 100vw;
    max-height: 150px;
    background-color: #ffffff00 !important;
    z-index: 1;
  }

  .upper-footer {
    font-size: 12px !important;
    justify-content: space-around;
  }
  img.Xlogo {
    width: 13.5px !important;
    margin-top: 2.5px;
  }

  div.companyAddress {
    width: 200px;
  }
  p.aboutCPOA {
    color: var(--cpofontgrey);
    font-size: smaller;
  }
  p.CPOAddress {
    color: #000;
    font-size: smaller;
  }
  .CPOemail {
    color: var(--cpotheme);
  }
}

li.footerFaq {
  border-bottom: 1px solid var(--cpotheme);
  /* width: 40px; */
  color: var(--cpotheme);
}

li.footerprivacy,
li.footeraboutus {
  border-bottom: 1px solid var(--cpotheme);
  /* width: 80px; */
  color: var(--cpotheme);
  margin-top: 5px;
}
