div.paymentSummaryContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.paymentSummaryCard {
  height: 400px;
  width: 300px;
  border: 1px solid var(--cpogrey);
  border-radius: 1rem;
  box-shadow: 8px 8px 26px rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
}

div.paymentSuccessMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  font-size: larger;
  font-weight: bolder;
  color: var(--cpotheme);
}

div.paymentHR {
  border-bottom: 2px dashed var(--cpogrey);
  margin: 20px;
}

div.paymentCompleteDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px;
  margin-top: 30px;
  font-size: small;
}
div.yourSummary {
  border-bottom: 1px solid var(--cpogrey);
  opacity: 0.7;
  display: flex;
  justify-content: center;
  margin: 0 30px 20px 30px;
}

div.paymentAmount {
  margin-top: 20px;
}

div.paymentsAtCPOAssist {
  font-size: 12px;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  margin-top: -5px;
}
